import * as React from 'react';
// import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {BottomFixed} from '../../assets/styledComponent/Style'
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';

export default function LoadingButtonsTransition() {
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);

return (
    <Box>
      <Box sx={{ml:3}}>
        <Typography variant="h5" sx={{fontWeight:"bold",mb:3}}>Notification</Typography>
      </Box>
      <FormControlLabel
        sx={{
          display: 'block',mx:3,
        }}
        control={
          <Switch
            checked={loading}
            onChange={() => setLoading(!loading)}
            name="loading"
            color="primary"
          />
        }
        label="Message"
      />

      <FormControlLabel
        sx={{
          display: 'block',mx:3,
        }}
        control={
          <Switch
            checked={loading1}
            onChange={() => setLoading1(!loading1)}
            name="loading1"
            color="primary"
          />
        }
        label="Task Update"
      />
      <FormControlLabel
        sx={{
          display: 'block',mx:3,
        }}
        control={
          <Switch
            checked={loading2}
            onChange={() => setLoading2(!loading2)}
            name="loading2"
            color="primary"
          />
        }
        label="Task Deadline"
      />
      <FormControlLabel
        sx={{
          display: 'block',mx:3,
        }}
        control={
          <Switch
            checked={loading3}
            onChange={() => setLoading3(!loading3)}
            name="loading3"
            color="primary"
          />
        }
        label="Mentor Help"
      />

      {/* <FormControlLabel
        sx={{
          display: 'block',mx:3,
        }}
        control={
          <Switch
            checked={loading}
            onChange={() => setLoading(!loading)}
            name="loading"
            color="primary"
          />
        }
        label="Task Deadline"
      />
      
      <FormControlLabel
        sx={{
          display: 'block',mx:3,
        }}
        control={
          <Switch
            checked={loading}
            onChange={() => setLoading(!loading)}
            name="loading"
            color="primary"
          />
        }
        label="Mentor Help"
      /> */}

      <Box sx={{p:2,width:"280px",mb:2,border:1,mx:6,borderRadius:3,...BottomFixed,backgroundColor:"#246BFD",color:"white"}}>
        <Typography textAlign={"center"}>Save Changes</Typography>
      </Box>
    </Box>
  );
}