import { Call, ChatBubble, Videocam } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

export default function ChatInfoHeader() {
  const headerIcon = {
    color: "rgba(0, 122, 255, 1)",
    backgroundColor: "rgba(237, 237, 255, 1)",
    p: 1.2,
    borderRadius: "50%",
    fontSize: "2.8rem",
    cursor: "pointer",
  };
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={2}
      >
        <Typography
          component="h2"
          variant="h6"
          fontWeight="600"
          fontFamily="inherit"
        >
          Front End Team
        </Typography>
        <Stack direction="row" gap={1}>
          <ChatBubble sx={headerIcon} />
          <Videocam sx={headerIcon} />
          <Call sx={headerIcon} />
        </Stack>
      </Stack>
      <Box
        py={2}
        borderTop={"1px solid rgba(0, 0, 0, 0.2)"}
        borderBottom={"1px solid rgba(0, 0, 0, 0.2)"}
      >
        <Typography component="p" variant="body1" fontSize="1.15rem">
          Team Memebers
        </Typography>
        <Typography component="p" variant="body2">
          Frontend admin, FS1, FS2, FS3, You
        </Typography>
      </Box>
    </>
  );
}
