import { Outlet } from "react-router-dom";
import Sidenav from "../components/header/SideNav";
import BottomTabs from "../components/bottom-tabs/BottomTabs";
import { useLocation } from "react-router";

export default function MainPageLayout() {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/profile" && <Sidenav />}
      <Outlet />
      {location.pathname !== "/notification" && <BottomTabs />}
    </>
  );
}
