import { Typography,Box } from '@mui/material'
import React, { useState } from 'react'
import {spaceBtn,vAlign} from '../../assets/styledComponent/Style'
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import image from '../../assets/images/Ellipse 100.png'
import image1 from '../../assets/images/Ellipse 101.png'
import image2 from '../../assets/images/Ellipse 102.png'
import TuneIcon from '@mui/icons-material/Tune';
import Grid from '@mui/material/Grid';
import BackgroundIMAGE from './../../assets/images/Profile1.png'
import Profile2 from '../../assets/images/Profile2.png'
import Profile3 from '../../assets/images/Profile3.png'
import Profile4 from '../../assets/images/Profile4.png'
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


function Home() {

    function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


  return (
    <Box sx={{mx:2}}>
      <Box sx={{mt:0}}>
      <Typography variant="h5" sx={{fontWeight:"bold",fontFamily:"inherit"}}>Time Limit</Typography>
      </Box>

{/* --------------------------------Search Button--------------------------------- */}
      <Box sx={{...vAlign}}>
      <Box>
      <FormControl sx={{ m: 1, width: '31ch',backgroundColor:"lightgrey",borderRadius:3}} >
      <InputLabel htmlFor="filled-adornment-password">Search task</InputLabel>
      <FilledInput
          sx={{borderRadius:3}}
            type="text"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="search input"
                  edge="end">
                   <SearchIcon /> 
                </IconButton>
              </InputAdornment>
            }
          />
      </FormControl>
      </Box>
      <Box sx={{border:1,borderRadius:1,p:1,mx:1,py:1,backgroundColor:"lightgrey"}}><TuneIcon/></Box>
      </Box>

<Box sx={{mt:3,border:1,borderRadius:4.6,boxShadow:3,borderColor:"lightgray",p:2.5,}}>
        <Box sx={{width:"100%"}}>
        {/*parent*/}
      <Box sx={{width:"100%"}}>
      {/*First*/}
      <Box component={"img"} src={BackgroundIMAGE} width={"100%"} sx={{borderRadius:1,borderColor:"lightgray"}}>
        
      </Box>
    <Box>
   <Typography> Creating ABC Mobile App <Typography sx={{color:"#54577A"}}> UI UX Design</Typography></Typography>
    </Box>
    <Box sx={{ width: '100%',mt:2 }}>
      <Box sx={{...spaceBtn}}><Typography>Progress</Typography><Typography sx={{color:"#546FFF"}}>90%</Typography></Box><LinearProgress  variant="determinate" valueBuffer={10} value={90} sx={{borderRadius:1,mt:1.3}}/>
    </Box>
    <Box sx={{...spaceBtn,mt:3}}>
      <Box sx={{display:"flex"}}><AccessTimeIcon/><Typography>1 Hour</Typography></Box>
      <Box sx={{my:1}}>
      <AvatarGroup sx={{mt:0}} >
          <Avatar alt="Remy Sharp" src={image} sx={{ width: 20, height: 20 }}/>
          <Avatar alt="Travis Howard" src={image1} sx={{ width: 20, height: 20 }} />
          <Avatar alt="Cindy Baker" src={image2} sx={{ width: 20, height: 20 }}/>
          <Avatar alt="Remy Sharp" src={image} sx={{ width: 20, height: 20 }}/>
          <Avatar alt="Travis Howard" src={image1} sx={{ width: 20, height: 20 }} />
      </AvatarGroup>
      </Box>
    </Box>
  </Box>
</Box>
  </Box>
{/* -------------------------------------Second---------------------------------------------   */}
<Box sx={{mt:3,border:1,borderRadius:4.6,boxShadow:3,borderColor:"lightgray",p:2.5,}}>
        <Box sx={{width:"100%"}}>
        {/*parent*/}
      <Box sx={{width:"100%"}}>
      {/*First*/}
      <Box component={"img"} src={Profile2} width={"100%"} sx={{borderRadius:1,borderColor:"lightgray"}}>
        
      </Box>
    <Box>
   <Typography> Creating Website<Typography sx={{color:"#54577A"}}> Web Developer</Typography></Typography>
    </Box>
    <Box sx={{ width: '100%',mt:2 }}>
      <Box sx={{...spaceBtn}}><Typography>Progress</Typography><Typography sx={{color:"#F8A700"}}>45%</Typography></Box><LinearProgress variant="determinate" valueBuffer={10} value={45} sx={{borderRadius:1,mt:1.3,color:"warning"}}/>
    </Box>
    <Box sx={{...spaceBtn,mt:3}}>
      <Box sx={{display:"flex"}}><AccessTimeIcon/><Typography>3 days Left</Typography></Box>
      <Box sx={{my:1}}>
      <AvatarGroup sx={{mt:0}} >
          <Avatar alt="Remy Sharp" src={image} sx={{ width: 20, height: 20 }}/>
          <Avatar alt="Travis Howard" src={image1} sx={{ width: 20, height: 20 }} />

      </AvatarGroup>
      </Box>
    </Box>
  </Box>
</Box>
  </Box>

{/* -----------------------------------------Third---------------------------------------------- */}
<Box sx={{mt:3,border:1,borderRadius:4.6,boxShadow:3,borderColor:"lightgray",p:2.5,}}>
        <Box sx={{width:"100%"}}>
        {/*parent*/}
      <Box sx={{width:"100%"}}>
      {/*First*/}
      <Box component={"img"} src={Profile3} width={"100%"} sx={{borderRadius:1,borderColor:"lightgray"}}>
        
      </Box>
    <Box>
   <Typography> Creating Color Palletes<Typography sx={{color:"#54577A"}}> UI UX Design</Typography></Typography>
    </Box>
    <Box sx={{ width: '100%',mt:2 }}>
      <Box sx={{...spaceBtn}}><Typography>Progress</Typography><Typography sx={{color:"#00A753"}}>100%</Typography></Box><Box sx={{}}><LinearProgress variant="determinate" valueBuffer={100} value={10} sx={{borderRadius:1,mt:1.3}}/></Box>
    </Box>
    <Box sx={{...spaceBtn,mt:3}}>
      <Box sx={{display:"flex"}}><AccessTimeIcon/><Typography>1 Hour</Typography></Box>
      <Box sx={{my:1}}>
      <AvatarGroup sx={{mt:0}} >
          <Avatar alt="Remy Sharp" src={image} sx={{ width: 20, height: 20 }}/>
          <Avatar alt="Travis Howard" src={image1} sx={{ width: 20, height: 20 }} />
          <Avatar alt="Remy Sharp" src={image} sx={{ width: 20, height: 20 }}/>
          <Avatar alt="Travis Howard" src={image1} sx={{ width: 20, height: 20 }} />

      </AvatarGroup>
      </Box>
    </Box>
  </Box>
</Box>
  </Box>

{/* -----------------------------------------Fourth---------------------------------------------- */}
<Box sx={{mt:3,mb:15,border:1,borderRadius:4.6,boxShadow:3,borderColor:"lightgray",p:2.5,}}>
        <Box sx={{width:"100%"}}>
        {/*parent*/}
      <Box sx={{width:"100%"}}>
      {/*First*/}
      <Box component={"img"} src={Profile4} width={"100%"} sx={{borderRadius:1,borderColor:"lightgray"}}>
        
      </Box>
    <Box>
   <Typography> Awesome Flutter Apps<Typography sx={{color:"#54577A"}}> Web Developer</Typography></Typography>
    </Box>
    <Box sx={{ width: '100%',mt:2 }}>
      <Box sx={{...spaceBtn}}><Typography>Progress</Typography><Typography sx={{color:"#546FFF"}}>75%</Typography></Box><Box sx={{}}><LinearProgress variant="determinate" valueBuffer={100} value={75} sx={{borderRadius:1,mt:1.3}}/></Box>
    </Box>
    <Box sx={{...spaceBtn,mt:3}}>
      <Box sx={{display:"flex"}}><AccessTimeIcon/><Typography>3 Hour</Typography></Box>
      <Box sx={{my:1}}>
      <AvatarGroup sx={{mt:0}} >
          <Avatar alt="Remy Sharp" src={image} sx={{ width: 20, height: 20 }}/>
          <Avatar alt="Travis Howard" src={image1} sx={{ width: 20, height: 20 }} />
          <Avatar alt="Remy Sharp" src={image} sx={{ width: 20, height: 20 }}/>
          

      </AvatarGroup>
      </Box>
    </Box>
  </Box>
</Box>
  </Box>

{/* --------------------------------------------------------------------------------------- */}

  

  



</Box>

    
  )
}

export default Home