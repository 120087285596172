import { createBrowserRouter } from "react-router-dom";
import Home from "./layouts/home/Home";
import TeamPage from "./layouts/team-page/TeamPage";
import CreateTask from "./layouts/create-task/CreateTask";
import ScheduleMeeting from "./layouts/schedule-meeting/ScheduleMeeting";
import MainPageLayout from "./routes/MainPageLayout";
import ChatPageLayout from "./routes/ChatPageLayout";
import Profile from "./layouts/Profile/profile";
import Notification from "./layouts/Profile/Notification";
import Timelimit from "./layouts/time-limit/TimeLimit";
import Individual from "./layouts/individual-task/Individual";
import ScreenShare from "./layouts/screen-share/ScreenShare";
import GroupInfo from "./layouts/group-info/GroupInfo";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPageLayout />,
    errorElement: <div>404 Not Found</div>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/notification",
        element: <Notification />,
      },
      {
        path: "/time-limit",
        element: <Timelimit />,
      },
      {
        path: "/individual",
        element: <Individual />,
      },
    ],
  },
  {
    path: "",
    element: <ChatPageLayout />,
    children: [
      {
        path: "/team-page",
        element: <TeamPage />,
      },
      {
        path: "/create-task",
        element: <CreateTask />,
      },
      {
        path: "/schedule-meeting",
        element: <ScheduleMeeting />,
      },
      {
        path: "/screen-sharing",
        element: <ScreenShare />,
      },
      {
        path: "/group-info",
        element: <GroupInfo />,
      },
    ],
  },
]);

export default router;
