import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ChatBottomDrawerList({
  drawerListData,
  toggleDrawer,
  title,
  setPriority,
}) {
  const iconColor = { color: "rgba(0, 122, 255, 1)" };

  const handleOptionClick = (text) => {
    if (text === "High" || text === "Medium") {
      setPriority(text);
    }
  };

  return (
    <Box p={1} role="presentation" onClick={toggleDrawer(false)}>
      <List bgcolor={"red"}>
        {title && (
          <ListItemText
            primary={title}
            primaryTypographyProps={{ fontSize: "1.2rem" }}
            sx={{ textAlign: "center" }}
          />
        )}
        {drawerListData.map((text, index) => (
          <ListItem key={index} disablePadding>
            <Link
              to={text?.url}
              style={{ textDecoration: "none", color: "black", width: "100%" }}
            >
              <ListItemButton
                sx={{ width: "100%" }}
                onClick={() => handleOptionClick(text.text)}
              >
                {text?.icon && (
                  <ListItemIcon sx={iconColor}>{text?.icon}</ListItemIcon>
                )}
                <ListItemText
                  primary={text.text}
                  primaryTypographyProps={
                    text.text === "High"
                      ? { color: "rgba(182, 18, 18, 1)", fontSize: "1.2rem" }
                      : { fontSize: "1.2rem" }
                  }
                  sx={!text.icon ? { textAlign: "center" } : null}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              primary="Cancel"
              primaryTypographyProps={{ fontSize: "1.2rem" }}
              sx={{ textAlign: "center", color: "rgba(0, 122, 255, 1)" }}
              onClick={toggleDrawer(false)}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
