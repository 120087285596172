import {
  ArrowBackIos,
  LocalPhoneOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function ChatHeader() {
  return (
    <Stack
      px={2}
      py={3}
      direction="row"
      justifyContent="space-between"
      bgcolor="rgba(246, 246, 246, 1)"
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link to="/">
          <ArrowBackIos
            sx={{ cursor: "pointer", color: "rgba(0, 122, 255, 1)" }}
          />
        </Link>
        <Box sx={{ display: "flex", alignItems: "center", gap: ".6rem" }}>
          <img
            src="https://via.placeholder.com/40"
            alt="Group Chat"
            style={{ borderRadius: "50%" }}
          />
          <Box>
            <Typography component="h1" variant="h6" fontWeight="600">
              Frontend Team
            </Typography>
            <Link to="/group-info" style={{ textDecoration: "none" }}>
              <Typography
                component="p"
                variant="body2"
                fontSize="12px"
                color="rgba(142, 142, 147, 1)"
              >
                tap here for memebers info
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <VideocamOutlined
          fontSize="medium"
          sx={{ cursor: "pointer", color: "rgba(0, 122, 255, 1)" }}
        />
        <LocalPhoneOutlined
          fontSize="medium"
          sx={{ cursor: "pointer", color: "rgba(0, 122, 255, 1)" }}
        />
      </Box>
    </Stack>
  );
}
