import ChatBottomDrawerList from "../bottom-drawer-list/ChatBottomDrawerList";
import { Drawer } from "@mui/material";

export default function ChatBottomDrawer({ toggleDrawer, open, drawerListData, title, setPriority }) {
  return (
    <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
      <ChatBottomDrawerList
        drawerListData={drawerListData}
        toggleDrawer={toggleDrawer}
        title={title}
        setPriority={setPriority}
      />
    </Drawer>
  );
}
