import { DoneAll } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";

export default function ChatSentMessage({ message }) {
  const [isSeen, setIsSeen] = useState(true);
  return (
    <Stack justifyContent="flex-end" flexDirection="row" py={1}>
      <Box
        px={2}
        py={1}
        bgcolor="rgba(220, 247, 197, 1)"
        borderRadius="6px"
        display="flex"
        gap={1}
      >
        <Typography component="p" variant="body1" width="max-content">
          {message}
        </Typography>
        <Typography
          component="p"
          variant="body2"
          fontSize="12px"
          color="rgba(0, 0, 0, 0.45)"
          width="max-content"
          alignSelf="flex-end"
        >
          10:00
        </Typography>
        <DoneAll
          fontSize="small"
          sx={{
            alignSelf: "flex-end",
            color: isSeen ? "rgba(0, 167, 83, 1)" : "rgba(151, 151, 151, 1)",
          }}
        />
      </Box>
    </Stack>
  );
}
