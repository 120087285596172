import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { spaceBtn, vAlign } from "../../assets/styledComponent/Style";
import Proimage from "../../assets/images/Rectangle 897.png";
import Circular from "./loader";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Charto from "./chart";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dropdown from "./dropdown";

function Profile() {
  return (
    <Box sx={{}}>
      {/* ----------------Grid--------------         */}
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <ArrowBackIosNewIcon />
          </Grid>
          <Grid item xs={7}>
            <Typography>Profile</Typography>
          </Grid>
        </Grid>
      </Box>
      {/* ---------------Profile Image-------------------- */}
      <Box textAlign="center">
        <Box
          component="img"
          sx={{
            height: 70,
            width: 70,
            borderRadius: 0,
          }}
          alt="Profile"
          src={Proimage}
        />
        <Box>
          <Typography>Desmon</Typography>
        </Box>
        <Box>
          <Typography sx={{ color: "lightgray" }}>Graphic Designer</Typography>
        </Box>
      </Box>

      {/* ----------------Running Tast----------------------------------     */}

      <Box
        sx={{
          border: 1,
          borderRadius: 3,
          backgroundColor: "black",
          color: "white",
          p: 2,
          mx: 1,
          mt: 4,
        }}
      >
        <Typography sx={{ fontSize: "25px" }}>Running Task</Typography>
        <Typography sx={{ textAlign: "end", fontSize: 40 }}>
          <Box sx={{ ml: 20 }}>
            <Box sx={{ ...vAlign }}>
              <Circular />
              <Typography sx={{ fontSize: "25px", mx: 2 }}>10</Typography>
            </Box>
          </Box>
        </Typography>
        <Box sx={{ ...spaceBtn }}>
          <Typography sx={{ fontSize: 40 }}>6</Typography>

          <Typography sx={{ fontSize: 25 }}>Tasks</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          border: 1,
          borderColor: "#F5F5F7",
          borderRadius: 3,
          backgroundColor: "#F5F5F7",
        }}
      >
        <Box sx={{ ...spaceBtn }}>
          <Box sx={{ p: 3, fontWeight: "bold" }}>Activity</Box>
          <Box sx={{ ...vAlign, mx: 1 }}>
            <Box sx={{ p: 3 }}></Box>
            <Box sx={{ mx: 2 }}>
              <Dropdown />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            border: 1,
            borderRadius: 3,
            mx: 3,
            mb: 3,
            backgroundColor: "#FFFFFF",
            borderColor: "#FFFFFF",
          }}
        >
          <Charto />
        </Box>
      </Box>

      {/* -----------------------Contact Details----------------------------- */}

      <Box
        sx={{
          border: 1,
          backgroundColor: "#F8F6FF",
          borderColor: "#F8F6FF",
          borderRadius: 3,
          mt: 4,
          mx: 1,
          py: 2,
        }}
      >
        <Box sx={{ ...vAlign }}>
          <Box>
            <AccessTimeIcon
              sx={{ color: "#37E2D5", mx: 3, verticalAlign: "center" }}
            />
          </Box>
          <Box>
            <Typography>
              Check-in Time{" "}
              <Typography sx={{ color: "grey" }}>
                Mar 21 2024, 08:35 AM
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: 1,
          backgroundColor: "#F8F6FF",
          borderColor: "#F8F6FF",
          borderRadius: 3,
          mt: 4,
          mx: 1,
          py: 2,
        }}
      >
        <Box sx={{ ...vAlign }}>
          <Box>
            <MailOutlineIcon
              sx={{ color: "#F73D93", mx: 3, verticalAlign: "center" }}
            />
          </Box>
          <Box>
            <Typography>
              Email{" "}
              <Typography sx={{ color: "grey" }}>abc321@gmail.com</Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: 1,
          backgroundColor: "#F8F6FF",
          borderColor: "#F8F6FF",
          borderRadius: 3,
          mt: 4,
          mx: 1,
          py: 2,
        }}
      >
        <Box sx={{ ...vAlign }}>
          <Box>
            <PhoneIcon
              sx={{ color: "#EE5007", mx: 3, verticalAlign: "center" }}
            />
          </Box>
          <Box>
            <Typography>
              Phone{" "}
              <Typography sx={{ color: "grey" }}>+01 123454256</Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: 1,
          backgroundColor: "#F8F6FF",
          borderColor: "#F8F6FF",
          borderRadius: 3,
          mt: 4,
          mb: 15,
          mx: 1,
          py: 2,
        }}
      >
        <Box sx={{ ...vAlign }}>
          <Box>
            <NotificationsIcon
              sx={{ color: "#8E92BC", mx: 3, verticalAlign: "center" }}
            />
          </Box>
          <Box>
            <Typography>Notification</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Profile;
