import { Outlet } from "react-router-dom";
import ChatHeader from "../components/chat/header/ChatHeader";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

export default function MainPageLayout() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ChatHeader />
        <Outlet />
      </LocalizationProvider>
    </>
  );
}
