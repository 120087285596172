import { Typography, Box } from "@mui/material";
import React, { useState } from "react";
import { spaceBtn, vAlign } from "../../assets/styledComponent/Style";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import image from "../../assets/images/Ellipse 100.png";
import image1 from "../../assets/images/Ellipse 101.png";
import image2 from "../../assets/images/Ellipse 102.png";
import Grid from "@mui/material/Grid";
import BackgroundIMAGE from "./../../assets/images/background.png";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function Home() {
  const [status, setStatus] = useState("All");
  const [Filterdate, setFilterdate] = useState([
    {
      content: "Landing page",
      description: "Web Design",
      buttonText: "New",
      status: "in-Progress",
      completedTime: "-",
      images: [image1, image2],
    },
    {
      content: "React JS for E-Commerce Web",
      description: "Web Design",
      buttonText: "New",
      status: "in-Progress",
      completedTime: "-",
      images: [image1, image2, image],
    },
    {
      content: "React JS for E-Commerce Web",
      description: "Web Design",
      buttonText: "On Hold",
      completedTime: "Exceeded",
      status: "Completed",
      images: [image1, image2],
    },
  ]);

  function valuetext(value) {
    return `${value}°C`;
  }

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ mx: 2 }}>
      <Box sx={{ mt: 0 }}>
        <Typography
          component={"h2"}
          sx={{ fontWeight: "bold", fontFamily: "inherit", fontSize: 18 }}
        >
          Hello Des
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          Let's finish your task today!
        </Typography>
      </Box>

      {/* --------------------------------Search Button--------------------------------- */}
      <Box>
        <FormControl sx={{ m: 1, width: "38ch" }}>
          <InputLabel htmlFor="filled-adornment-password">
            Find your task
          </InputLabel>
          <FilledInput
            sx={{ borderRadius: 3 }}
            type="text"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="search input" edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      {/* -----------------------------Time Limit-------------------------------- */}

      <Box sx={{ mt: 2, ...spaceBtn }}>
        <Typography
          component={"h2"}
          sx={{ fontWeight: "bold", fontFamily: "inherit", fontSize: 18 }}
        >
          Time Limit
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          View All
        </Typography>
      </Box>

      {/* -------------------------Grid Button---------------------------------- */}
      <Box sx={{}}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                border: 1,
                borderRadius: 3,
                boxShadow: 3,
                borderColor: "lightgray",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {/*parent*/}
                <Box sx={{ position: "relative", width: "100%" }}>
                  {/*First*/}
                  <Box
                    component={"img"}
                    src={BackgroundIMAGE}
                    width={"100%"}
                    sx={{
                      position: "absolute",
                      zIndex: 0,
                      border: 1,
                      borderRadius: 3,
                      borderColor: "lightgray",
                    }}
                  ></Box>
                  {/*second*/}
                  <Box sx={{ position: "absolute", zIndex: 1, top: 25 }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        p: 1,
                        color: "white",
                        fontWeight: 600,
                      }}
                    >
                      Creating ABC Mobile app{" "}
                      <Typography sx={{ fontSize: 12, color: "lightgrey" }}>
                        UI/UX design
                      </Typography>{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mt: 10, ...spaceBtn }}>
                <Box sx={{ width: "58%", mt: 2, ml: 1 }}>
                  <LinearProgress
                    variant="buffer"
                    value={60}
                    valueBuffer={10}
                    sx={{ borderRadius: 1 }}
                  />
                </Box>

                <Box sx={{ my: 1 }}>
                  <AvatarGroup sx={{ mt: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={image}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Avatar
                      alt="Travis Howard"
                      src={image1}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Avatar
                      alt="Cindy Baker"
                      src={image2}
                      sx={{ width: 20, height: 20 }}
                    />
                  </AvatarGroup>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                border: 1,
                borderRadius: 3,
                boxShadow: 3,
                borderColor: "lightgray",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {/*parent*/}
                <Box sx={{ position: "relative", width: "100%" }}>
                  {/*First*/}
                  <Box
                    component={"img"}
                    src={BackgroundIMAGE}
                    width={"100%"}
                    sx={{
                      position: "absolute",
                      zIndex: 0,
                      border: 1,
                      borderRadius: 3,
                      borderColor: "lightgray",
                    }}
                  ></Box>
                  {/*second*/}
                  <Box sx={{ position: "absolute", zIndex: 1, top: 25 }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        p: 1,
                        color: "white",
                        fontWeight: 600,
                      }}
                    >
                      Creating ABC Mobile app{" "}
                      <Typography sx={{ fontSize: 12, color: "lightgrey" }}>
                        UI/UX design
                      </Typography>{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mt: 10, ...spaceBtn }}>
                <Box sx={{ width: "58%", mt: 2, ml: 1 }}>
                  <LinearProgress
                    variant="buffer"
                    valueBuffer={10}
                    value={60}
                    sx={{ borderRadius: 1 }}
                  />
                </Box>
                <Box sx={{ my: 1 }}>
                  <AvatarGroup sx={{ mt: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={image}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Avatar
                      alt="Travis Howard"
                      src={image1}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Avatar
                      alt="Cindy Baker"
                      src={image2}
                      sx={{ width: 20, height: 20 }}
                    />
                  </AvatarGroup>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/*MY-TASK in-PROGRESS  COMPLETED BUtton*/}

      <Box sx={{ ...spaceBtn, mt: 3 }}>
        <Box
          sx={{ border: 1, borderRadius: 5, p: 1, backgroundColor: "#E5EAFC" }}
        >
          <Typography
            sx={{ fontWeight: "bold" }}
            onClick={() => setStatus("All")}
          >
            My Task
          </Typography>
        </Box>
        <Box
          sx={{ border: 1, borderRadius: 4, p: 1, backgroundColor: "#E5EAFC" }}
        >
          <Typography
            sx={{ fontWeight: "bold" }}
            onClick={() => setStatus("in-Progress")}
          >
            In-Progress
          </Typography>
        </Box>
        <Box
          sx={{ border: 1, borderRadius: 4, p: 1, backgroundColor: "#E5EAFC" }}
        >
          <Typography
            sx={{ fontWeight: "bold" }}
            onClick={() => setStatus("Completed")}
          >
            Completed
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mb: 8 }}>
        {Filterdate.filter((item) =>
          status === "All" ? item : item.status === status
        ).map((item, index) => {
          return (
            <Box key={index} sx={{ boxShadow: 3, borderRadius: 3 }}>
              <Box sx={{ p: 2, mt: 5, borderRadius: 2 }}>
                <Box sx={{ ...spaceBtn }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {item.content}
                  </Typography>
                  <Box>
                    {status === "All" && (
                      <AvatarGroup>
                        {item.images.map((item1, index1) => {
                          return (
                            <Avatar
                              key={index1}
                              alt="Remy Sharp"
                              sx={{ width: 24, height: 24 }}
                              src={item1}
                            />
                          );
                        })}
                      </AvatarGroup>
                    )}
                    {status === "in-Progress" && (
                      <CircularProgressWithLabel value={80} />
                    )}
                  </Box>
                </Box>
                <Typography sx={{ fontSize: "13px", color: "grey" }}>
                  {item.description}
                </Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box sx={{ ...spaceBtn }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color:
                        status !== "Completed"
                          ? "grey"
                          : item.completedTime === "On-Time"
                          ? "green"
                          : "red",
                      ...vAlign,
                    }}
                  >
                    <AccessTimeIcon sx={{ color: "blue" }} />
                    {status === "All"
                      ? "Deadline"
                      : status === "in-Progress"
                      ? "Remaining Days"
                      : item.completedTime === "On-Time"
                      ? item.completedTime
                      : "Exceeded Deadline"}
                  </Typography>

                  <Box>
                    {status === "All" && (
                      <Stack direction="row" sx={{}}>
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "green",
                            color: "white",
                            borderRadius: 4.5,
                          }}
                        >
                          New
                        </Button>
                      </Stack>
                    )}

                    {status !== "All" && (
                      <AvatarGroup>
                        {item.images.map((item1, index1) => {
                          return (
                            <Avatar
                              key={index1}
                              alt="Remy Sharp"
                              sx={{ width: 24, height: 24 }}
                              src={item1}
                            />
                          );
                        })}
                      </AvatarGroup>
                    )}

                    <Box></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default Home;
