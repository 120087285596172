import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { BottomFixed } from "../../assets/styledComponent/Style";
import { Theme } from "@mui/material";
import { ButtonBase, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Groups3Icon from "@mui/icons-material/Groups3";
import PersonIcon from "@mui/icons-material/Person";

const CustomTab = styled(Tab)(({ theme }) => ({
  minWidth: `${100 / 4}vw`, // Adjust width as needed
  padding: theme.spacing(1, 2),
  // Add other custom styles
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const customTabClasses = {
    root: {
      maxWidth: 90, // Adjust the width as needed (in pixels)
    },
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      classes={customTabClasses}
      sx={{ backgroundColor: "lightgrey", ...BottomFixed, maxWidth: "100vw" }}
      aria-label="basic tabs example"
    >
      <CustomTab
        icon={<HomeIcon />}
        aria-label="phone"
        onClick={() => {
          navigate("/");
        }}
      />
      <CustomTab
        icon={<CalendarMonthIcon size="small" />}
        aria-label="favorite"
        onClick={() => {
          navigate("/team-page");
        }}
      />
      <CustomTab icon={<Groups3Icon size="small" />} aria-label="person" />
      <CustomTab
        icon={<PersonIcon size="small" />}
        aria-label="person"
        onClick={() => {
          navigate("/profile");
        }}
      />
    </Tabs>
  );
}
