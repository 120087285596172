import {
  Add,
  AttachFile,
  CameraAltOutlined,
  KeyboardVoiceOutlined,
} from "@mui/icons-material";
import { Box, Stack, TextareaAutosize } from "@mui/material";
import { useState } from "react";
import ChatBottomDrawer from "../bottom-drawer/ChatBottomDrawer";

export default function ChatBottomInput({ drawerListData }) {
  const inputField = {
    border: "none",
    outline: "none",
    width: "100%",
    p: "0px 10px",
    borderRadius: "4px",
    fontFamily: "Inherit",
  };

  const iconColor = { color: "rgba(0, 122, 255, 1)" };

  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  return (
    <Box sx={{ position: "fixed", bottom: 0, width: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        bgcolor="rgba(246, 246, 246, 1)"
        px={2}
        py={1}
        gap={2}
      >
        <Add fontSize="medium" sx={iconColor} onClick={toggleDrawer(true)} />
        <Stack
          direction="row"
          gap={1}
          p={1}
          alignItems="center"
          bgcolor="rgba(255, 255, 255, 1)"
          borderRadius="8px"
          flexGrow={1}
        >
          <TextareaAutosize aria-label="empty textarea" style={inputField} />

          <AttachFile sx={iconColor} />
        </Stack>
        <Stack direction="row" gap={1.5}>
          <CameraAltOutlined sx={iconColor} />
          <KeyboardVoiceOutlined sx={iconColor} />
        </Stack>
      </Stack>

      <ChatBottomDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        drawerListData={drawerListData}
      />
    </Box>
  );
}
