import { Stack, Typography } from "@mui/material";

export default function ChatReceivedMessage() {
  return (
    <Stack
      my={1}
      px={2}
      py={1}
      sx={{
        bgcolor: "rgba(246, 246, 246, 1)",
        width: "max-content",
        borderRadius: "6px",
        maxWidth: "80%",
      }}
    >
      <Typography component="p" variant="body1" mb={1} pr={4}>
        Today's Task?
      </Typography>

      <Typography
        component="p"
        variant="body1"
        color="rgba(0, 0, 0, 0.45)"
        textAlign="end"
      >
        09:30
      </Typography>
    </Stack>
  );
}
