import { Typography, Box } from "@mui/material";
import React, { useState } from "react";
import { spaceBtn, vAlign } from "../../assets/styledComponent/Style";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import image from "../../assets/images/Ellipse 100.png";
import image1 from "../../assets/images/Ellipse 101.png";
import image2 from "../../assets/images/Ellipse 102.png";
import TuneIcon from "@mui/icons-material/Tune";
import Grid from "@mui/material/Grid";
import BackgroundIMAGE from "./../../assets/images/Profile1.png";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import GroupIcon from "@mui/icons-material/Group";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function Home() {
  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ mx: 2 }}>
      <Box sx={{ mt: 0 }}>
        <Typography
          component={"h2"}
          sx={{ fontWeight: "bold", fontFamily: "inherit", fontSize: 18 }}
        >
          UI UX Design
        </Typography>
      </Box>

      {/* --------------------------------Search Button--------------------------------- */}

      {/* -----------------------------Time Limit-------------------------------- */}

      {/* <Box sx={{mt:2,...spaceBtn}}>
      <Typography component={"h2"}sx={{fontWeight:"bold",fontFamily:"inherit",fontSize:18}}>Time Limit</Typography>
<Typography  variant="caption" display="block" gutterBottom>View All</Typography>
      </Box> */}

      {/* -------------------------Grid Button---------------------------------- */}

      {/* ----------------mt:3,borderRadius:4.6,boxShadow:3,borderColor:"lightgray",p:2.5,------ */}

      <Box sx={{}}>
        <Box sx={{ width: "100%" }}>
          {/*parent*/}
          <Box sx={{ width: "100%" }}>
            {/*First*/}
            <Box
              component={"img"}
              src={BackgroundIMAGE}
              width={"100%"}
              sx={{ borderRadius: 1, borderColor: "lightgray", mt: 2 }}
            ></Box>
            <Box>
              <Typography sx={{ fontWeight: "bold" }}>
                {" "}
                Creating Abc Mobile Apps{" "}
                <Typography sx={{ color: "#8E92BC", mt: 2 }}>
                  {" "}
                  UI UX Design . Apps Design
                </Typography>
              </Typography>
            </Box>

            <Box sx={{}}>
              <Box sx={{ ...vAlign, mt: 3 }}>
                <Box sx={{ display: "flex" }}>
                  <GroupIcon />
                </Box>
                <Box sx={{ my: 1 }}>
                  <AvatarGroup sx={{ mt: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={image}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Avatar
                      alt="Travis Howard"
                      src={image1}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Avatar
                      alt="Cindy Baker"
                      src={image2}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Avatar
                      alt="Remy Sharp"
                      src={image}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Avatar
                      alt="Travis Howard"
                      src={image1}
                      sx={{ width: 20, height: 20 }}
                    />
                  </AvatarGroup>
                </Box>
                <Box sx={{ display: "flex", ml: 14 }}>
                  <AccessTimeIcon />
                  <Typography>1 Hour</Typography>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2 }}>
                Description
              </Typography>
              <Box content={"p"} sx={{ mt: 2 }}>
                Lorem ipsum dolor amot consectuer, Lorem ipsum dolor amot
                consectuer, Lorem ipsum dolor amot consectuer, Lorem ipsum dolor
                amot consectuer, Lorem ipsum dolor amot consectuer, Lorem ipsum
                dolor amot consectuer, Lorem ipsum dolor amot consectuer,
              </Box>
            </Box>

            <Box sx={{ ...spaceBtn, mt: 3 }}>
              <Box>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  CheckList
                </Typography>
              </Box>
              <AddIcon sx={{ mx: 4 }} />
            </Box>

            <Box sx={{ ...vAlign, mt: 3 }}>
              <CheckCircleIcon sx={{ color: "#546FFF" }} />
              <Typography sx={{ ml: 1, textDecoration: "line-through" }}>
                Lorem ipsum dolor amot consectuer
              </Typography>
            </Box>
            <Box sx={{ ...vAlign }}>
              <CheckCircleIcon sx={{ color: "#546FFF" }} />
              <Typography sx={{ ml: 1, textDecoration: "line-through" }}>
                Lorem ipsum dolor amot consectuer
              </Typography>
            </Box>
            <Box sx={{ ...vAlign }}>
              <CheckCircleIcon sx={{ color: "#546FFF" }} />
              <Typography sx={{ ml: 1 }}>
                Lorem ipsum dolor amot consectuer
              </Typography>
            </Box>
            <Box sx={{ ...vAlign }}>
              <CheckCircleIcon sx={{ color: "#546FFF" }} />
              <Typography sx={{ ml: 1 }}>
                Lorem ipsum dolor amot consectuer
              </Typography>
            </Box>
            {/*second*/}
          </Box>
          <Box sx={{ ...vAlign, mt: 2 }}>
            <AddIcon sx={{}} />
            <Box sx={{ ml: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Add Comments
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              p: 2,
              width: "280px",
              mb: 10,
              border: 1,
              mt: 2,
              mx: 3.5,
              borderRadius: 3,
              backgroundColor: "#246BFD",
              color: "white",
            }}
          >
            <Typography textAlign={"center"}>Submit</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
