import { MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";

export default function CustomSelect({ value, handleOnChange, menuItems }) {
  return (
    <Select
      labelId="demo-multiple-name-label"
      id="demo-multiple-name"
      multiple
      value={value}
      onChange={handleOnChange}
      input={<OutlinedInput />}
      sx={{ width: "100%" }}
    >
      {menuItems.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
}
