import { AddCircleOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Input,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../components/ui/custom-select/CustomSelect";
import SubmitButton from "../../components/ui/submit-button/SubmitButton";

export default function CreateTask() {
  const navigate = useNavigate();
  const textAreaAutoSizeStyle = {
    width: "100%",
    outline: "none",
    padding: "10px 10px",
    borderRadius: "4px",
    fontFamily: "Inherit",
    fontSize: "inherit",
  };

  const names = ["Oliver Hansen", "Van Henry", "April Tucker", "Ralph Hubbard"];
  const [assignee, setAssignee] = useState([names[0]]);

  const handleAssigneeChange = (event) => {
    const {
      target: { value },
    } = event;

    setAssignee(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted");
    navigate("/team-page");
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Stack gap={3} px={2} py={4}>
          <Box>
            <Typography component="p" variant="overline">
              Title
            </Typography>
            <TextField
              hiddenLabel
              label="Title"
              id="outlined-size-small"
              defaultValue=""
              size="medium"
              fullWidth
            />
          </Box>
          <Stack direction="row" gap={3}>
            <Box>
              <Typography component="p" variant="overline">
                Due Date
              </Typography>
              <DatePicker />
            </Box>
            <Box>
              <Typography component="p" variant="overline">
                Time
              </Typography>
              <TimePicker />
            </Box>
          </Stack>
          <Box>
            <Typography component="p" variant="overline">
              Description
            </Typography>
            <TextareaAutosize
              aria-label="Description"
              placeholder="Description"
              style={textAreaAutoSizeStyle}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography component="p" variant="overline">
                Assigned To
              </Typography>
              <CustomSelect
                value={assignee}
                handleOnChange={handleAssigneeChange}
                menuItems={names}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography component="p" variant="overline">
                Follower
              </Typography>
              <CustomSelect
                value={assignee}
                handleOnChange={handleAssigneeChange}
                menuItems={names}
              />
            </Grid>
          </Grid>
          <Box>
            <Typography component="p" variant="overline">
              Upload Files
            </Typography>
            <FormLabel
              htmlFor="contained-button-file"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                padding: "12px 24px 12px 12px",
                backgroundColor: "rgba(245, 245, 245, 1)",
              }}
            >
              <Input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                sx={{ display: "none" }}
              />
              <Typography component="p" variant="body1">
                {"Upload Files"}
              </Typography>
              <AddCircleOutlined
                sx={{
                  position: "absolute",
                  right: "28px",
                  color: "rgba(0, 0, 0, 0.4)",
                }}
              />
            </FormLabel>
          </Box>
          <SubmitButton text="Create" />
        </Stack>
      </form>
    </Box>
  );
}
